/**
 * Provides a refined footer design with five columns to better organize
 * navigation for a non-profit organization:
 *
 * 1) Branding & Social Media
 * 2) Events
 * 3) Our Work
 * 4) About Us
 * 5) Legal
 *
 * @example
 * ```tsx
 * import { NpoFooter } from '.';
 *
 * export default function SomePage() {
 *   return (
 *     <>
 *       // Page Content
 *       <NpoFooter />
 *     </>
 *   );
 * }
 * ```
 * @throws No known errors
 */

import { Facebook, Instagram } from 'lucide-react';
import Link from 'next/link';

export function NpoFooter() {
  return (
    <footer className="bg-card text-card-foreground mt-8 border-t">
      <div className="container mx-auto px-4 py-10">
        {/* Column 1: Branding & Social Media */}
        <div className="mb-12 flex w-full flex-col items-center justify-center text-center">
          <div className="mb-4 flex items-center justify-center space-x-2">
            <span className="text-2xl font-bold tracking-tight">
              MusicLocal
            </span>
          </div>
          <p className="text-muted-foreground text-sm italic">
            Empowering local music communities
          </p>
          <div className="mt-4 flex items-center space-x-4">
            <Link
              href="https://www.facebook.com/profile.php?id=61564022398831"
              className="text-muted-foreground hover:text-primary transition-colors"
              aria-label="Visit our Facebook"
              target="_blank"
            >
              <Facebook className="h-5 w-5" />
            </Link>
            <Link
              href="https://www.instagram.com/musiclocal_tallahassee/"
              className="text-muted-foreground hover:text-primary transition-colors"
              aria-label="Visit our Instagram"
              target="_blank"
            >
              <Instagram className="h-5 w-5" />
            </Link>
          </div>
        </div>
        {/*
          Use a responsive grid that expands to 5 columns on large screens.
          - 1 column on extra-small screens
          - 2 columns on small screens
          - 4 columns on medium screens
          - 4 columns on large screens
        */}
        <div className="mx-6 grid gap-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          {/* Column 2: Events */}
          <div>
            <h3 className="text-muted-foreground mb-3 text-sm font-semibold uppercase">
              Events
            </h3>
            <nav className="flex flex-col gap-2 text-sm">
              <Link href="/tallahassee" className="hover:underline">
                Tallahassee
              </Link>
            </nav>
          </div>

          {/* Column 3: Our Work */}
          <div>
            <h3 className="text-muted-foreground mb-3 text-sm font-semibold uppercase">
              Our Work
            </h3>
            <nav className="flex flex-col gap-2 text-sm">
              <Link href="/" className="hover:underline">
                Home
              </Link>
              {/* <Link href="/npo/purpose" className="hover:underline">
                Purpose
              </Link> */}
              {/* <Link href="/npo/local-live-music" className="hover:underline">
                Local Live Music
              </Link> */}
              <Link href="/npo/issues" className="hover:underline">
                Issues &amp; Solutions
              </Link>
              <Link href="/npo/donate" className="hover:underline">
                Donate
              </Link>
              {/* <Link href="/npo/flywheel" className="hover:underline">
                Impact Flywheel
              </Link> */}
              {/* <Link href="/npo/outcomes" className="hover:underline">
                Outcomes
              </Link> */}
            </nav>
          </div>

          {/* Column 4: About Us */}
          <div>
            <h3 className="text-muted-foreground mb-3 text-sm font-semibold uppercase">
              About Us
            </h3>
            <nav className="flex flex-col gap-2 text-sm">
              {/* <Link href="/npo/about" className="hover:underline">
                Who We Are
              </Link> */}
              <Link href="/npo/approach" className="hover:underline">
                Approach
              </Link>
              <Link href="/npo/disclosures" className="hover:underline">
                Charitable Disclosures
              </Link>
            </nav>
          </div>

          {/* Column 5: Legal */}
          <div>
            <h3 className="text-muted-foreground mb-3 text-sm font-semibold uppercase">
              Legal
            </h3>
            <nav className="flex flex-col gap-2 text-sm">
              <Link href="/legal/privacy" className="hover:underline">
                Privacy Policy
              </Link>
              <Link href="/legal/terms-of-service" className="hover:underline">
                Terms of Service
              </Link>
              <Link href="/legal/accessibility" className="hover:underline">
                Accessibility Statement
              </Link>
            </nav>
          </div>
        </div>

        <div className="text-muted-foreground mx-6 mt-8 border-t pt-4 text-center text-sm">
          &copy; {new Date().getFullYear()} MusicLocal Inc. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
