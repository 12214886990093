import { Button } from '@demeter/shadcn-ui';
import { Heart } from 'lucide-react';
import Link from 'next/link';

export function DonateButton() {
  return (
    <div className="pr-1 sm:pr-4">
      <Button
        className="xs:px-2 flex h-8 gap-2 text-base sm:px-6"
        variant="secondary"
        asChild
      >
        <Link href="/npo/donate">
          <Heart width={18} height={18} /> Donate
        </Link>
      </Button>
    </div>
  );
}
